.card {
    background: white;
    border-radius: 10px;
    margin: 20px;
    flex: 1;
    max-width: 300px;
    text-align: center;
    padding: 20px;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0;
}

.card-title,.card-subtitle {
    font-size: 1.5em;
    margin: 2rem 0;
    color:var(--blue_color);
}



.card-description {
    font-size: 1rem;
    color: var(--grey_word);
    text-align:justify;
    line-height:1.5rem ;
}

.card:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .card {
        max-width: 45%;
        margin: 10px;
    }

    .card-title,
    .card-subtitle {
        font-size: 1.25em;
        margin: 1.5rem 0;
    }

    .card-description {
        font-size: 0.875em;
    }
}

@media (max-width: 992px) {
    .card {
        max-width: 100%;
        margin: 10px 0;
    }

    .card-title,
    .card-subtitle {
        font-size: 1.25em;
        margin: 1.5rem 0;
    }

    .card-description {
        font-size: 0.875em;
    }
}

@media (max-width: 768px) {
    .card {
        max-width: 100%;
        margin: 10px 0;
        padding: 15px;
    }

    .card-title,
    .card-subtitle {
        font-size: 1.15em;
        margin: 1rem 0;
    }

    .card-description {
        font-size: 0.875em;
        line-height: 1.25rem;
    }
}

@media (max-width: 576px) {
    .card {
        max-width: 100%;
        margin: 5px 0;
        padding: 10px;
    }

    .card-title,
    .card-subtitle {
        font-size: 1em;
        margin: 0.75rem 0;
    }

    .card-description {
        font-size: 0.75em;
        line-height: 1.25rem;
    }
}