.carousel-container {
    width: 80%;
    margin: auto;
}

.slider-item {
    transition: transform 0.3s, opacity 0.3s;
    padding: 0;
    margin: -20px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

.slider-item img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    padding: 0.5rem;
}


.slick-center {
    opacity: 1;
}


.slick-prev, .slick-next {
    top: auto;
    bottom: -70px; /* Adjust this value based on your needs */
    transform: translateY(-80%);
    background: transparent; /* Ensure background is transparent */
    border: none; /* Remove any default border */
}

.slick-prev {
    left: 50%; /* Adjust these values if needed */
    transform: translateX(-150%) translateY(-50%);
}

.slick-next {
    right: 50%; /* Adjust these values if needed */
    transform: translateX(150%) translateY(-50%);
}

.slick-prev:before, .slick-next:before {
    color:#fff;
    font-size: 24px; /* Adjust the size of the arrow */
}