

/* style */
#header-container {
    /* background-image: url(../../public/images/pexels-ivan-samkov-5676744.jpg); */
    position: relative;
    padding: 2% 5%;
    width: 100%;
    background-size:cover;
    /* Ensures the image covers the entire container */
    background-position: center;
    /* Centers the image within the container */
    background-repeat: no-repeat;
    /* Prevents the image from repeating */
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}
#header-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(217, 217, 217, 0) 0%, rgba(88, 92, 158, 0.6) 100%);
    pointer-events: none; /* Ensure the pseudo-element does not block interactions */
    z-index: 1; /* Place it behind the content but above the background */
  }



.header-content {
    z-index: 3;
    margin-top: 10%;
    position: relative; 
    text-align: left;
}

.header-content h1 {
    color: #FFF;
    font-size: 2.5rem;
    line-height: 3rem;
    
}

.header-content h1 span {
    color: var(--primary_color);
}

.header-content p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #FFF;
    margin-bottom: 1.25rem;
    max-width: 70%;
}

.apply-button {
    background-color:var(--primary_color);
    color: white;
    padding: 0.625rem 1.25rem;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 50px;
    box-shadow: var(--shadow_color);

}

.apply-button:hover {
    background-color:var(--second_color);
    
}

.apply-button:focus{
    background-color: var(--third_color);
}


#school-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
}

.school-section {
    position: relative;
    width: 100%;
    color: white;
    text-align: left;
    padding: 2rem 5%;
}

.school-section.full-width {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    align-items: center;
    justify-content: center;
}

.eva-school{
    background-image: url(../../public/images/Frame\ 1.png);
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
}

.osa-school{
    background-image: url(../../public/images/Frame\ 3.png);
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
}

.kai-school{
    background-image: url(../../public/images/Frame\ 2.png);
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
}


.school-section.half-width {
    flex: 1 1 50%; /* Occupies half of the width*/
}


.content-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-logo img {
    max-width: 100%;
    height: auto;
    margin-right: 20px;
}
.content-word {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5rem;
}

.content-word h2 {
    font-size: 2rem;
    color: #fff;
    margin-top: 4rem;
}

.content-word p {
    font-size: 0.875rem;
    
}

.content-word .button {
    background-color: var(--primary_color);
    color: white;
    border: none;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s;
    max-width: 8rem;
}

.content-word button:hover {
    background-color: var(--second_color);
}


.offer-container{
    background-image: url(../../public/images/utof.png);
    background-size: cover;
    background-position: center;
    /* Centers the image within the container */
    background-repeat: no-repeat;
    max-width: 100%;
    max-height: 100%;
    max-height: 80vh;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 10%;
}

.chat-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    text-align: center;
}

.chat-section p {
    font-size: 1.5em;
    font-weight: 700;
    color: var(--blue_color);
    margin-bottom: 1.25rem;
}

.help-button {
    background-color: var(--primary_color); /* Red color for the button */
    color: white;
    padding: 0.625rem 3.125rem;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.help-button:hover {
    background-color: var(--second_color); /* Darker red on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .header-content h1 {
        font-size: 2.25rem;
    }

    .header-content p {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .school-section.full-width {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto; /* Ensure rows adjust based on content */
    }

    .content-logo {
        grid-row: 1 / 2; /* Occupies the first row */
    }

    .content-word {
        grid-row: 2 / 4; /* Occupies the second and third rows */
    }
    .school-section.half-width {
        flex: 1 1 100%;
    }

    .header-content h1 {
        font-size: 2rem;
    }

    .header-content p {
        font-size: 0.875rem;
    }

    .content-word h2 {
        font-size: 1.75rem;
    }

    .chat-section p {
        font-size: 1.25rem;
    }
}

@media (max-width: 768px) {
    .header-content {
        text-align: center;
    }

    .header-content h1 {
        font-size: 1.75rem;
    }

    .header-content p {
        font-size: 0.75rem;
    }

    .content-word h2 {
        font-size: 1.5rem;
    }

    .apply-button,
    .content-word .button,
    .help-button {
        padding: 0.5rem 1rem;
    }
}

@media (max-width: 576px) {
    .header-content h1 {
        font-size: 1.5rem;
    }

    .header-content p {
        font-size: 0.75rem;
    }

    .content-word h2 {
        font-size: 1.25rem;
    }

    .apply-button,
    .content-word .button,
    .help-button {
        padding: 0.5rem 1rem;
    }
}