.navbar-nav {
    font-weight: 1rem;
}

.navbar-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-top: 1rem;
    padding-right: 1rem;
    flex-wrap: wrap;
}

.navbar-contact .contact-item span {
    margin-left: 10px;
}

.navbar-logo {
    width: 100%;
    height: 100%;
    /* Adjust the height as needed */
    overflow: hidden;
    flex: 3;
}

#basic-navbar-nav {
    flex: 7;
}

.navbar-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This property crops the image */
    object-position: center;
    /* Center the image */
}

.dropdown-menu {
    background-color:var(--primary_color); /* Set background color */
    width: 100%;
  }
  
 .dropdown-item {
    color: black; /* Set font color */
    white-space: normal;
    text-align: center;
  }
.dropdown-item:hover,
.dropdown-item:focus {
    color:white;
    background-color: var(--primary_color);
  }

