.gallery-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    flex-wrap: wrap;
}

.gallery-container {
    display: grid;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.layout {
    width: 100%;
    height: 100%;
}

.grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will crop the image to fit the container */
    transition: transform 0.3s ease;
}

.grid-item img:hover {
    transform: scale(1.05);
}
