:root {
    --primary_color: #F32C2C;
    --second_color: #D41010;
    --third_color: #A70C0C;
    --blue_color:#1A2189;
    --shadow_color: #18274B;
    --white_word: #FFFF;
    --title_size: 52px;
    --grey_word:#606060;
}

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    text-align: center;
    height: 100%;
}

h1 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--blue_color);
    line-height: 1.5rem;

}

.section{
    line-height: 1.8rem;
    margin: 3rem 26%;
    color: var(--grey_word);
    text-transform: uppercase;;
}

.section h2{
    text-align: center;
}

.button{
    text-decoration: none;
}

img{
    border: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: clip;
    overflow-clip-margin: content-box;
    vertical-align: middle;
}
