.card-section {
    width: 80%;
    margin: auto;
    text-align: center;
}


.cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


@media (max-width: 767px) {
    .cards-container {
        display: block; /* Display as block for mobile screens */
    }
    .card {
        margin-bottom: 20px; /* Add margin at the bottom for spacing between cards */
    }
}